import React from 'react';

import './programs.styles.scss';
import Button from '../../components/button/button.component';

import codingprg from '../../assets/images/program-free-class.png';
import teambasedprg from '../../assets/images/program-community.png';
import holidaycampprg from '../../assets/images/program-montesorri.png';
import bdaypartyprg from '../../assets/images/program-rewards.png';
import girlscoutprg from '../../assets/images/program-finger-tips.png';

const Programs = () => (
    <div className="container">
             <div className="row desktop-programs-version">
                <div className="col-md-12">
            
                <ul className="nav md-pills nav-justified pills-rounded pills-purple-gradient cw-programs">
                    <li className="nav-item codingprg">
                    <a className="nav-link active" data-toggle="tab" href="#panel100" role="tab"><div></div> CODING AND ROBOTICS CLASSES</a>
                    </li>
                    <li className="nav-item teambasedprg">
                    <a className="nav-link" data-toggle="tab" href="#panel101" role="tab"><div></div> TEAM-BASED ACTIVITIES</a>
                    </li>
                    <li className="nav-item holidaycampprg">
                    <a className="nav-link" data-toggle="tab" href="#panel102" role="tab"><div></div> SUMMER AND HOLIDAY CAMPS</a>
                    </li>
                    <li className="nav-item bdaypartyprg">
                    <a className="nav-link" data-toggle="tab" href="#panel103" role="tab"><div></div> BIRTHDAY PARTIES</a>
                    </li>
                    <li className="nav-item girlscoutprg">
                    <a className="nav-link" data-toggle="tab" href="#panel104" role="tab"><div></div> GIRL'S SCOUT</a>
                    </li>
                </ul>
            
                
                <div className="tab-content">
                    <div className="tab-pane fade in show active" id="panel100" role="tabpanel">
                        <div className="container">
                            <div className="detail-box col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6 coding-robotics">
                                        <div className="detail-img"></div>
                                    </div>
                                    <div className="col-md-12 col-lg-6 orange-bkg">
                                        <h4 className="bungee">CODING & ROBOTICS</h4>
                                        <p>Empower your child to choose their own path. For beginners to more advanced techies and everyone in between, we offer year-round, highly personalized tech classes with rolling enrollments.</p>
                                        <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel101" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 team-based">
                                    <div className="detail-img"></div>
                                </div>
                                <div className="col-md-12 col-lg-6 orange-bkg">
                                    <h4 className="bungee">TEAM-BASED ACTIVITIES</h4>
                                    <p>Unlock your child's true potential and help them connect with their peers like never before. We encourage our students to participate in competitions and challenges that encourage real-world problem solving and invaluable team-building</p>
                                    <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel102" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 camps">
                                    <div className="detail-img"></div>
                                </div>
                                <div className="col-md-12 col-lg-6 orange-bkg">
                                    <h4 className="bungee">SUMMER AND HOLIDAY CAMPS</h4>
                                    <p>Summer sessions can help your children learn, grow and keep their cool! Children love these week-long sessions because they get to build and program robots, drones and more. Using drag-and-drop technology like scratch or more advanced technology like python and raspberry pi, your children will be engineering some really cool stuff—the only limit is their imagination! Weekly coding sessions will also be offered for children who want to work on building video games and web apps.</p>
                                    <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div className="tab-pane fade" id="panel103" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 bday-party">
                                    <div className="detail-img"></div>
                                </div>
                                <div className="col-md-12 col-lg-6 orange-bkg">
                                    <h4 className="bungee">BIRTHDAY PARTIES</h4>
                                    <p>Celebrate the future with a tech-powered birthday party at Code Wyz! Children get together to play Minecraft and Roblox, build LEGO robots, code video games, create cartoon characters and so much more. It’s a unique experience for your child—and an easy one for you. Our Code Wyz staff handles all the setup and clean up. Plus, coaches will be on hand to help children with their party projects and ensure everyone has fun!</p>
                                    <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel104" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 girls-scout">
                                    <div className="detail-img"></div>
                                </div>
                                <div className="col-md-12 col-lg-6 orange-bkg">
                                    <h4 className="bungee">Girl scouts</h4>
                                    <p>Encourage girls to explore STEM! We work closely with troop leaders to craft a custom experience for the girls based on their interests and troop size.</p>
                                    <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                </div>
            </div> 
        </div>
);

export default Programs