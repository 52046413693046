import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import ProgramsPower from '../../assets/images/program-wrapper-img.png';
//import ProgramHeaderImage from '../../assets/images/contact-header-image.png';
import ProgramHeaderMobileImg from '../../assets/images/Program1.jpg';

import './programs.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import FirstLegoImg from '../../assets/images/FLL&Robotics-orange.png';
import TechnovationImg from '../../assets/images/Technovation.png';
import BirthdayPartyImg from '../../assets/images/Birthday1.png';
import CampImg from '../../assets/images/SummerCamps-orange.png';
import Successlevel from '../../assets/images/bubble-31.png';

//const ProgramsPage = () => ( 
class ProgramsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.fallclasses} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={ProgramHeaderMobileImg} />
                    <title>Code Wyz | Programs</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="programs-page-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-12 col-lg-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">PROGRAMED FOR SUCCESS AT ALL LEVELS</h2> 
                                    <h4 className="header-text cw-dark-blue">&nbsp;</h4>
                                    {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={ProgramHeaderMobileImg} alt="Programs Mobile"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper programs-wrapper">
                        <div className="container programs-container">
                            {/* <div className="row">
                                <div className="image col-md-6 order-md-2">
                                    <img src={ProgramsPower} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-1">
                                    <h3 className="bungee">What powers us</h3>
                                    <p>At Code Wyz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem solving. Best of all, anyone can learn to code—no matter their age, tech proficiency or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wyz!</p>
                                    <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">FIND A CENTER NEAR ME</Button>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="image col-md-6 order-md-1">
                                    <img src={Successlevel} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-2">
                                    <h3 className="bungee">PROGRAMED FOR SUCCESS AT ALL LEVELS</h3>
                                    <p>There are many paths to success depending on your child’s interests, skill level and prior coding experience. And, there is <strong>no limit</strong> to your child’s potential here at Code Wyz, which is why we allow children to choose their own track or explore multiple tracks at once! Scroll to learn more about their options or download our tech track to see how it all connects.</p>
                                    {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <h3 className="bungee cw-dark-blue center col-md-12">Coding Classes</h3>
                            <p>The world is changing—and coding is quickly becoming a basic literacy for this new digital age.  Learning to code not only helps your child learn how to use technology, it also fosters their problem-solving, communication, creativity and math skills. Children have fun, make friends and gain the confidence to take on just about anything! Learn more about the awesome, invaluable skills your child can acquire at any age. </p>
                        </div>
                    </div>
                    <div className="fall_class_middle_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 center">
                                    <div className="fall_class_techs">
                                        <div className="fall_class_tech_single">
                                            {/* <div className="tech_logo">
                                                <img src={TechLogo1Img} alt="tech logo"/>							
                                            </div> */}
                                            <h3 className="bungee cw-orange">Ages 7 – 9</h3>
                                            {/* <h4 className="bungee orange_txt">Ages 7 – 9</h4> */}
                                            <p className="">Start with a bang! Our seven to nine year-olds take on real coding projects right away. They build multi-level video games, create and animate their own characters, craft music videos, model physics simulations and more. In addition to nurturing their critical problem-solving and algorithm design skills, this hands-on, visual programming environment introduces children to programming, programming concepts and prepares them for text-based coding.</p>
                                        </div>
                                        <div className="fall_class_tech_single">
                                            {/* <div className="tech_logo">
                                                <img src={TechLogo2Img} alt="tech logo two" />							
                                            </div> */}
                                            <h3 className="bungee cw-orange">Ages 9 – 12</h3>
                                            {/* <h4 className="bungee orange_txt">Ages 9 – 12</h4> */}
                                            <p className="">Children in this age group quickly become true keyboard warriors. In a game-based environment, your child writes the actual code that controls the avatars. They are introduced to Python, which is the most popular programming language, and then begin building websites in HTML, CSS and Javascript before eventually moving to more advanced computer science concepts. The curriculum for this tier covers 85% of Stanford’s Introduction to Computer Science course.</p>
                                        </div>
                                        
                                        <div className="fall_class_tech_single">
                                            {/* <div className="tech_logo">
                                                <img src={TechLogo3Img} alt="tech logo three" />							
                                            </div> */}
                                            <h3 className="bungee cw-orange">Ages 13+</h3>
                                            {/* <h4 className="bungee orange_txt">Ages 13+</h4> */}
                                            <p className="">Pre-teens and teens begin to power their own destiny. They choose to hone their skills in Java, Python, Unity, or take a deeper dive into HTML, CSS, Bootstrap and Javascript. They learn basic computer science concepts from variables, data manipulation, string concatenation, conditionals, for loops to more advanced concepts such as arrays and object-oriented programming.</p>
                                        </div>
                                    </div> 
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>                      
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body-wrapper programs-wrapper">
                        <div className="container programs-container">
                            <div className="row">
                                <div className="image col-md-6 order-md-1">
                                    <img src={FirstLegoImg} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-2">
                                    <h3 className="bungee">ROBOTICS & FLL</h3>
                                    <p>Children of all ages literally build the future by creating and coding their own robots. From modeling push-and-pull forces to mimicking a frog’s metamorphosis, our robots can be designed and programmed to do almost anything. Your child will learn about various sensors and develop algorithms to complete challenges with their robots. Additionally, every fall and spring, Code Wyz students join over 40,000 children to build a robot that solves a real-world challenge! By participating in LEGO league championships, your child will have fun, learn to collaborate with peers, design and build a robot and compete for national accolades!</p>
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                                <div className="image col-md-6 order-md-2">
                                    <img src={TechnovationImg} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-1">
                                    <h3 className="bungee">Girls' Technovation Challenge</h3>
                                    <p>Girls with dreams become women with vision. That’s why Technovation invites teams of girls from all over the world to learn, master and apply the skills needed to solve real-world problems in an annual technology challenge. At Code Wyz, we take pride in preparing and empowering girls by providing the mentorship they need to compete in Technovation—and the world beyond. </p>
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fall_class_middle_section">
                        <div className="container">
                            <div className="row">
                                <div className="image col-md-6 order-md-1">
                                    <img src={BirthdayPartyImg} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-2">
                                    <h3 className="bungee cw-dark-blue">Birthday Parties</h3>
                                    <p>Celebrate the future with a tech-powered birthday party at Code Wyz! Children get together to play Minecraft and Roblox, build LEGO robots, code video games, create cartoon characters and so much more. It’s a unique experience for your child—and an easy one for you. Our Code Wyz staff handles all the setup and clean up. Plus, coaches will be on hand to help children with their party projects and ensure everyone has fun!</p>
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="row">
                                <div className="image col-md-6 order-md-2">
                                    <img src={CampImg} alt="Program Power"/>
                                </div> 
                                <div className="text abs right col-md-6 order-md-1">
                                    <h3 className="bungee cw-dark-blue">Summer and Holiday Camps</h3>
                                    <p>Summer sessions can help your children learn, grow and keep their cool! Children love these week-long sessions because they get to build and program robots, drones and more. Using drag-and-drop technology like scratch or more advanced technology like python and raspberry pi, your children will be engineering some really cool stuff—the only limit is their imagination! Weekly coding sessions will also be offered for children who want to work on building video games and web apps.</p>
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="container">
                        <h3 className="bungee center cw-dark-blue focused-programs">About our classes</h3>
                    </div>
                    <Programs /> */}
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default ProgramsPage;