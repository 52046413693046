import React from 'react';
import firebase from '../Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
import LearnToCodeHeaderImg from '../../assets/images/bubble-33.jpg';
//import LearnToCodeHeaderImg from '../../assets/images/learn_to_code.png';
import ScreencastImg from '../../assets/images/screencast.jpg';

import OnlineCodingSlider from '../../components/slick-slider/online-coding-slider.component';
import OnlineCodingMobileSlider from '../../components/slick-slider/online-coding-slider-mobile.component';

import CodingFeature1Img from '../../assets/images/coding_feature1.png';
import CodingFeature2Img from '../../assets/images/coding_feature2.png';
import CodingFeature3Img from '../../assets/images/coding_feature3.png';
import CodingFeature4Img from '../../assets/images/coding_feature4.png';
import CodingFeature5Img from '../../assets/images/coding_feature5.png';

import RobloxTeaser1Img from '../../assets/images/roblox_teaser1.jpg';
import RobloxTeaser2Img from '../../assets/images/roblox_teaser2.jpg';
import RobloxTeaser3Img from '../../assets/images/roblox_teaser3.jpg';

// import RobloxTeaser1Img from '../../assets/images/roblox_teaser1.jpg';
// import RobloxTeaser2Img from '../../assets/images/blog_item7.jpg';
// import RobloxTeaser3Img from '../../assets/images/roblox_teaser3.jpg';




import './online-coding.styles.scss';


class OnlineCoding extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            }
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.fallclasses} contactlink={this.state.data.email}/>);
        }else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div className="special-page online-coding" id=""> 
                <MetaTags>
                    <title>{`Online Fall Classes for Kids In ${aboutdata.city} ${aboutdata.state} | The Code Wyz`}</title>
                    <meta name="description" content={`With our Online Fall Classes for Kids In ${aboutdata.city} ${aboutdata.state}, you get access to highly personalized tech classes that are better than the best`}/>
                    <meta name="keywords" content="coding for kids"></meta>
                </MetaTags>
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">LEARN TO CODE.</h2> 
                                <h4 className="header-text cw-dark-blue">Make new friends. All from home!</h4>    
                                <Button color="orange" link="/locations" size="medium">FIND A CENTER NEAR ME</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={LearnToCodeHeaderImg} alt="online fall coding"/>
                        </div>
                    </div>
                </div>
                <div className="blue_section online_coding_camps_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="online_coding_box">
                                    <div className="image">
                                        <img src={ScreencastImg} />
                                    </div>
                                    <div className="text">
                                        <h4 className="bungee">Join the</h4>
                                        <h2 className="bungee">Code Wyz Coder Community</h2>
                                        <p>Join this awesome community of child coders who love coding while sharing their ideas with other students. Make new friends for life, create cool projects, learn new technology, collaborate with other students, earn rewards and challenge your learning with the latest technology!</p>
                                    </div>
                                </div>
                                
                                <div className="online_coding_tech_slider">
                                    <div className="slider technologies_slider desktop-slider">
                                        <OnlineCodingSlider />
                                    </div>
                                    <div className="slider technologies_slider mobile-slider">
                                        <OnlineCodingMobileSlider />
                                    </div>
                                </div>
                                
                                
                                
                                <div className="online_coding_structure">
                                
                                    <p>Structured, personalized and fun learning your children will love. A chance to collaborate with other students, build your own apps, challenge your skills and test the latest technology. A safe place for children to learn and make friends and memories for life.</p>
                                    <div className="titles">
                                        <h4 className="bungee">All skill levels welcomed.</h4>
                                        <h2 className="bungee">Ages 7-9, 10-12 and 13+</h2>
                                    </div>
                                </div>
                                
                                <div className="online_coding_features">
                                    <h2 className="bungee">ONLINE CODING CLASSES</h2>
                                    <ul>
                                        <li>
                                            <img src={CodingFeature1Img} />
                                            <p><span>Safe and Secure</span> Online Environment</p>
                                        </li>
                                        <li>
                                            <img src={CodingFeature2Img} />
                                            <p><span>Project driven</span> learning</p>
                                        </li>
                                        <li>
                                            <img src={CodingFeature3Img} />
                                            <p><span>Socialization</span> opportunities</p>
                                        </li>
                                        <li>
                                            <img src={CodingFeature4Img} />
                                            <p><span>Really cool</span> rewards</p>
                                        </li>
                                        <li>
                                            <img src={CodingFeature5Img} />
                                            <p><span>Automated progress</span> reports</p>
                                        </li>
                                    </ul>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="online_coding_camp_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">			
                                <h2 className="bungee">Find a locally owned Code Wyz near you</h2>
                                <p>For an amazing online experience</p>
                                
                                <div className="online_coding_camp">
                                    <div className="online_coding_camp_block">
                                        <div className="image">
                                            <img src={RobloxTeaser1Img} />
                                        </div>
                                        <h3 className="bungee">Online camps</h3>
                                        <a href="./locations">FIND A CENTER NEAR ME</a>
                                    </div>
                                    
                                    <div className="online_coding_camp_block">
                                        <div className="image">
                                            <img src={RobloxTeaser2Img} />
                                        </div>
                                        <h3 className="bungee">Online classes</h3>
                                        <a href="./locations">FIND A CENTER NEAR ME</a>
                                    </div>
                                    
                                    {/* <div className="online_coding_camp_block">
                                        <div className="image">
                                            <img src={RobloxTeaser3Img} />
                                        </div>
                                        <h3 className="bungee">virtual bday parties</h3>
                                        <a href="./locations">Find a center near me</a>
                                    </div> */}
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                {floatingdata}
            </div>
        );
    }
}

export default OnlineCoding
