import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/Group-4.png';
import ContactHeaderImage from '../../assets/images/contact-header-image.png';
import ContactHeaderMobileImg from '../../assets/images/contact-image.png';

import './contact.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';

//const ContactPage = () => (
class ContactPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            defaultdata: {
                addressstreet: "9 Cornerstone Sq",
                city: "Westford",
                state: "MA",
                zip: "01886",
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                monday: "Mon: 9:00AM - 5PM",
                tuesday: "Tue: 9:00AM - 5PM",
                wednesday: "Wed: 9:00AM - 5PM",
                thursday: "Thur: 9:00AM - 5PM",
                friday: "Fri: 9:00AM - 5PM",
                franchise: "yes",
                locations: "/locations" 
            },
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation
        }
    } 

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        let renderdaysList = "";
        let daysOpenList = this.state.data.daysopen;
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.fallclasses} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }

        if(!daysOpenList){
            renderdaysList ="";
        }else {
            renderdaysList = daysOpenList.map((item, index) => 
            <div key={index}>{item}</div>
            );
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={ContactHeaderMobileImg} />
                    <title>Code Wyz | Contact Us</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="contact-page-content">
                    <div className="container header-text-container"> 
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">Contact us</h2> 
                                    <h4 className="header-text cw-dark-blue">Code Wyz is always here to help your child learn, grow and gain the skills they need to succeed now and later. We know that once your child tries coding, they'll fall in love! We're here to answer any questions you might have.</h4>
                                    {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img src={ContactHeaderMobileImg} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="container contact-info-contact-page">
                        <div className="row">
                            <div className="col col-md-12 col-lg-4">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>ADDRESS:</h5>
                                <span>{aboutdata.addressstreet}<br/> {aboutdata.city}, {aboutdata.state} {aboutdata.zip}</span>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-phone fa-5x cw-orange"></i></div>
                                <h5>CONTACT:</h5>
                                <span><a href={`tel:${aboutdata.phone}`}>{aboutdata.phone}</a> <br/>
                                <a href={`mailto:${aboutdata.email}`}>{aboutdata.email}</a></span>
                            </div>
                            <div class="col">
                                <div class="contact-circle"><i class="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>HOURS:</h5>
                                <p> 
                                    {renderdaysList} 
                                </p>
                            </div>
                        </div>
                    </div>        
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default ContactPage;