import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/bubble-34.jpg';
import AboutPower from '../../assets/images/image-1.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import AboutGivingBack from '../../assets/images/giving-back-image-2.jpg';

import './about-us.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';


class AboutPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }

    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.fallclasses} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        
        
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={AboutPageImg} />
                    <title>Code Wyz | About Us</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="about-page-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">ABOUT US</h2> 
                                    <h4 className="header-text cw-dark-blue">Learn more about the vision and mission of Code Wyz</h4>
                                    {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={AboutPageImg} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper about-us-wrapper">
                        <div className="container about-us-container">
                            <div className="row">
                                <div className="image col-md-12 col-lg-6 order-md-1">
                                    <img src={AboutPower} alt="About Power"/>
                                </div> 
                                <div className="text abs right col-md-12 col-lg-6 order-md-2 align-middle">
                                    <h3 className="bungee">What powers us</h3>
                                    <p>At Code Wyz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem solving. Best of all, anyone can learn to code—no matter their age, tech proficiency or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wyz!</p>
                                    {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                                </div>
                            </div>
                        </div>
                        <div className="container empty-space bw-4"></div>
                        <div className="mid-section">
                            <div className="container">
                                <div className="row">
                                    <div className="text col-md-12 col-lg-6">
                                        <h3 className="bungee">The spark that got us started</h3>
                                        <p>There is no limit to your child’s potential. We encourage them to create by completing projects rather than levels. This approach fosters creativity and fuels the entrepreneurial spirit that may later lead to world-changing technologies—you never know, you may be raising the next Bill Gates! </p>
                                    </div>
                                    <div className="image img-right col-md-12 col-lg-6 about-video">
                                        {/* <img src={AboutSpark} alt="About Spark"/> */}
                                        <iframe src="https://www.youtube.com/embed/KADKFbckuVY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    {/* <div className="container">
                        <h3 className="bungee center cw-dark-blue focused-programs">Focused Programs. infinite potential</h3>
                    </div> */}
                    <div className="container">
                        <div className="row giving-back">
                            <div className="col-lg-6 col-md-12 giving-back-img">
                                <img src={AboutGivingBack} alt="About Giving Back"/>
                            </div>
                            <div className="col-lg-6 col-md-12 giving-back-text">
                                <h3 className="bungee cw-dark-blue">Giving back as we grow</h3>
                                <p>See how learning to code can change your child’s life. Start with a no-obligation introductory class that can be done from the comfort of your home today—all Code Wyz classes are currently being offered online!</p>
                                {/* <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}
export default AboutPage;