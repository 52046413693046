import React from 'react';
import {Switch, Route, Redirect, Link} from 'react-router-dom';

import './App.css';

import HomePage from './pages/homepage/homepage.component';
import AboutPage from './pages/about-us/about-us.component';
import ContactPage from './pages/contact/contact.component';
import ProgramsPage from './pages/programs/programs.component';
import BirthdayPartiesPage from './pages/birthday-parties/birthday-parties.component';
import FindACoursePage from './pages/find-a-course/find-a-course.component';
import CampsPage from './pages/camps/camps.component';
import CompetitionsPage from './pages/competitions/competitions.component';
import FAQPage from './pages/faqs/faqs';
import RobloxPage from './pages/roblox';
import OnlineCodingPage from './pages/online-coding';

import WelcomeMessagePage from './pages/welcome-message';
import BlogPage from './pages/blog/blog.component';
import NotFound from './pages/notfound';
// import Sessions from './pages/sessions.component';
//import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import PrivacyPolicyPage from './pages/privacy-policy';
import TermsAndConditionsPage from './pages/terms-and-conditions';
import ThankYouPage from './pages/thank-you';




function App() {
  return (
    <div>
      {/* <Header /> */}
      <Switch>

        {/* General Pages */}
        <Route exact path='/' component={HomePage} />
        <Route exact path='/roblox' component={RobloxPage} />
        <Route exact path='/online-coding-classes-and-camps' component={OnlineCodingPage} />
        <Redirect from="/programs" to="/after-school-coding-programs" />
        <Route exact path='/welcome' component={WelcomeMessagePage} />
        <Route exact path='/blog' component={BlogPage} />
        <Route exact path='/about-us' component={AboutPage} />
        <Route exact path='/contact-us' component={ContactPage} />
        <Route exact path='/after-school-coding-programs' component={ProgramsPage} />
        <Route exact path='/birthday-parties' component={BirthdayPartiesPage} />
        <Route exact path='/findmycourse' component={FindACoursePage} />
        <Route exact path='/camps' component={CampsPage} />
        <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
        <Route exact path='/terms-and-conditions' component={TermsAndConditionsPage} />
        <Route exact path='/thank-you' component={ThankYouPage} />

        {/* <Route exact path='/pricing' component={PricingPage} /> */}
        
       

       
        {/* <Redirect from='/static/media/Westboro-Fall-Brochure.f06d14fe.pdf' to='/westborough-ma/fall-schedule' /> */}
        {/* <Redirect from='/rfc' to='https://info.tutordoctorfranchise.com/cw-rfc' /> */}
        <Route exact path="/rfc" render={() => (window.location = "https://info.tutordoctorfranchise.com/cw-rfc")} />
        <Route exact path="/projects" render={() => (window.location = "https://students.thecodewiz.com/student-projects/")} />
        {/* <Route exact path="/westborough-ma/fall-schedule" render={() => (window.location = "https://www.thecodewiz.com/static/media/Westboro-Fall-Brochure.f06d14fe.pdf")} /> */}

        <Route component={NotFound} />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
