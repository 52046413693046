import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import RewardsImg from '../../assets/images/Ellipse-orange.png';
import ProgressImg from '../../assets/images/Ellipse-green.png';
import CommunityImg from '../../assets/images/Ellipse-light-blue.png';
import FreeClassImg from '../../assets/images/Ellipse-purple.png';

import './slick-slider.styles.scss';
 
export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };
  return (
    <Slider {...settings}>
        <div>
            <img src={RewardsImg} alt="rewards"/>
            <h5 className="bungee">REALLY COOL REWARDS</h5>
            <p>Our students love to learn—and we love to reward them for it! For the completion of each project (as well as little milestones along the way), students earn badges and “WizBucks,” which are redeemable for Code Wyz gear and other awesome prizes.</p>
        </div>
        <div>
            <img src={ProgressImg} alt="Progress"/>
            <h5 className="bungee">PROGRESS AT YOUR FINGER TIPS</h5>
            <p>We know it’s important for you to stay connected to your child’s success. That’s why we send automated progress report emails that let you know exactly what your child is working on and when they have completed a project. </p>
        </div>
        <div>
            <img src={CommunityImg} alt="Community"/>
            <h5 className="bungee">COMMUNITY CONNECTIVITY</h5>
            <p>For accountability and added inspiration, all students have access to a personalized web portal. Here, they can view their own work and check out projects completed by Code Wyz children across the nation. </p>
        </div>
        <div>
            <img src={FreeClassImg} alt="Free class"/>
            <h5 className="bungee">FREE, NO-STRINGS TRIAL CLASS</h5>
            <p>See how learning to code can change your child’s life. Start with a no-obligation introductory class that can be done from the comfort of your home today—all Code Wyz classes are currently being offered online!</p>
        </div>
    </Slider>
  );
}