import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/Group-4.png';
import CompetitionTech from '../../assets/images/competition-technovation.png';
import CompetitionFLL from '../../assets/images/competition-first-lego.png';
import CompetitionHeaderImage from '../../assets/images/competition-header.png';
import CompetitionHeaderMobileImg from '../../assets/images/competition-header-1.jpg';

import './competitions.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';

//const CompetitionsPage = () => (
class CompetitionsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            }
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.fallclasses} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={CompetitionHeaderMobileImg} />
                    <title>Code Wyz | Competitions</title>
                </MetaTags>
                <Header location={this.state.location} />
                <div className="competitions-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-12 col-lg-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">COMPETITIONS</h2> 
                                    <h4 className="header-text cw-dark-blue">By participating in our competitions, our students learn how to work together as a team, friendly competition and public speaking skills.  </h4>
                                    {/* <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">FIND A CENTER NEAR ME</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={CompetitionHeaderMobileImg} alt="Competition Page"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper competitions-wrapper">
                        <div className="container competitions-top">
                            <div className="row">
                                <div className="image col-md-6">
                                    <img src={CompetitionTech} alt="Competition Technovation"/>
                                </div> 
                                <div className="text col-md-6 cw-dark-blue">
                                    <div className="text-details">
                                        <h3 className="bungee">technovation</h3>
                                        <h5 className="bungee cw-orange">HELPING GIRLS CHANGE THE WORLD</h5>
                                        <p>Through Technovation, the world’s largest technology entrepreneurship, the future truly is female. Girls of all ages from all over are invited to solve real problems using technology. Discover how Code Wyz prepares your child for the competition and paves the way for her success!</p>
                                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-reserve"> 
                                <div className="text col-md-6 cw-dark-blue">
                                    <div className="text-details">
                                        <h3 className="bungee">FIRST LEGO LEAGUE</h3>
                                        <h5 className="bungee cw-orange">BUILDING A BETTER FUTURE</h5>
                                        <p>Let LEGO® inspire, challenge and grow your child like never before. FIRST® LEGO® League invites youth to sharpen their STEM skills with competitions centered around building their very own creation. See how Code Wyz provides the building blocks they need to succeed.</p>
                                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                    </div>
                                </div>
                                <div className="image col-md-6 cw-dark-blue">
                                    <img src={CompetitionFLL} alt="Competition FLL"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                {floatingdata}
            </div>
        )
    }

};

export default CompetitionsPage;