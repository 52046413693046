import React from 'react';
import MenuItem from '../menu-item/menu-item.component';

import './menu.styles.scss';

class Menu extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.name,
            menu: [],
            sections: [
                {
                    title: 'Home',
                    linkUrl: '/',
                    id: 1,
                    htmlId: 'homeDropdownMenu',
                    dropdown: [{
                        title: 'ABOUT US',
                        dpLinkUrl: '/about-us',
                        id: 10
                    },
                    // {
                    //     title: 'BLOG',
                    //     dpLinkUrl: '/blog',
                    //     id: 11
                    // },
                    {
                        title: 'CONTACT US',
                        dpLinkUrl: '/contact-us',
                        id: 12
                    }]
                },
                    {
                    title: 'PROGRAMS',
                    linkUrl: '/after-school-coding-programs',
                    id: 2,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'CLASSES',
                    linkUrl: '#',
                    id: 4,
                    htmlId: '',
                    dropdown: [{
                        title: 'ONLINE CLASSES',
                        dpLinkUrl: '/online-coding-classes-and-camps',
                        id: 13
                    }
                    ,
                    {
                        title: 'FIND A COURSE',
                        dpLinkUrl: '/findmycourse',
                        id: 14
                    }
                    ]
                },
                {
                    title: 'CAMPS',
                    linkUrl: '/camps',
                    id: 5,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'COMPETITIONS',
                    linkUrl: '/coding-robotics-competitions-for-kids',
                    id: 6,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'FAQs',
                    linkUrl: '/kids-coding-frequently-asked-questions',
                    id: 7,
                    htmlId: '',
                    dropdown: []
                },
                {
                    title: 'LOCATIONS',
                    linkUrl: '/code-wyz-locations',
                    id: 8,
                    dropdown: []
                },
                {
                    title: 'FRANCHISING',
                    linkUrl: 'https://codewizfranchise.com/',
                    id: 9,
                    dropdown: []
                }
            ]
            
        }
    }

    render() {
        
        if(this.state.location === "" || typeof this.state.location === "undefined"){
            this.state.menu = this.state.sections;
        }
        
        
        return (
            <div className="menu-navigation">
                <ul className="navbar-nav mr-auto col-md-12">
                {
                    this.state.menu.map(({id,...otherSectionProps}) => (
                        <MenuItem key={id} {...otherSectionProps} />
                    ))
                }
                </ul>
            </div>
            
        );
    }
}

export default Menu;

